export const calculateMultiplicationVisualization = (help, type) => {
    let helptxt = help;
    let aa = 1, bb = 1;
    let isrectangle = false;
    
    if (type === "times" && help && help.includes("///")) {
        let helparr = help.split("///");
        //helptxt = help.replace("///", " mal ");
        //helptxt = helptxt + " - Rechteck:";
        aa = helparr[0];
        bb = helparr[1];
        helptxt = `
        An <b>area</b> model is a way to represent a rectangle by drawing a grid of squares. Each square represents one unit of area.
        In this case, we can draw a grid of <b>${aa}</b> units by <b>${bb}</b> units.
        How many squares do we have in the grid?
        We have a total of <b>${aa*bb}</b> squares.
        This is the same as the <b>area</b> of the rectangle!
        `
        isrectangle = true;
    }
    
    let isnumberline = false;
    
    if (type === "add" && help && help.includes("/a/")) {
        let helparr = help.split("/a/");
        aa = Number(helparr[0]);
        bb = Number(helparr[1]);
        helptxt = `Start at Zero (0). Move ${Math.abs(aa)} units to the ${aa<0 ? "left (minus)" : "right (plus)" }, then ${Math.abs(bb)} units to the ${bb < 0 ? "left" : "right"}${aa*bb>0?" again.":"."}
        Click the button below to see if you have counted the steps correctly.`;
        isnumberline = true;
    }
    // Return the calculated values that you need in Task.js
    return { helptxt, aa, bb, isrectangle, isnumberline };
};

