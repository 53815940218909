import "./App.css";
import style from "./components/_styles";

import { React, useState, useEffect, useMemo } from "react";
import Task from "./Task";
import CreateTask from "./CreateTask";
import {AiOutlinePlus} from 'react-icons/ai'
import {AiOutlineQuestion} from 'react-icons/ai'
import {CgMathEqual} from 'react-icons/cg'
import {AiOutlineBulb} from 'react-icons/ai'
//import GdprPopup from './components/GdprPopup';
//import Footer from './Footer';
//import { Auth } from './components/_auth'

//import TriangleCanvas from './components/TriangleCanvas'; 

function App() {

  const [currentTask, setCurrentTask] = useState({});
  const [showHelp, setShowHelp] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showExplainer, setShowExplainer] = useState(false);
  const types = useMemo(() => [
    {typ: "add",    btn: "AddSub",        txt: "Mastering Basic Addition and Subtraction"},
    {typ: "addsub", btn: "AddSub (( ))",     txt: "Plus & Minus: Mastering (( )) in Arithmetic"},
    //{typ: "addtxt", btn: "Balance",       txt: "Account balance, deposit, withdrawal"},
    {typ: "times",  btn: "Times Tables",         txt: "Multiplication tables"},
    //{typ: "times",  btn: "Times 00.000",  txt: "Multiplication 0.00 & 1000"},
    {typ: "lin1",   btn: "x + a = b",     txt: "Plus & Minus: Unlocking X"},
    //{typ: "prop",   btn: "PROP",          kbd: "g", txt: "Proportionalität & Dreisatz", hasFilter: true},
    {typ: "lin3",   btn: "a * x = b",     txt: "Times and Fractional Equations"},
    //{typ: "lin2",   btn: "ax + b = c",    txt: "Lineare Gleichungen"},
    {typ: "frac",   btn: "Fractions",     txt: "Simplifying and Reducing Fractions"},
    //{typ: "prozent", btn: "PROZENT",      kbd: "g", txt: "Anteile & Prozent", hasFilter: true},
    //{typ: "linfun", btn: "LINEAR y(x)",   txt: "Lineare Funktionen"}, 
    //{typ: "quad",   btn: "QUAD y(x)",     txt: "Quadratische Funktionen"}
    //{typ: "potenzen", btn: "POWERs",      kbd: "o", txt: "Potenzrechnung", hasFilter: true},
  ], []); // Empty dependency array means it only runs once on mount

  const types_times = useMemo(() => [
    {typ: "times1000",  btn: "1000 x 100",         txt: "Multiply 10s, 100s, 1000s"},
    {typ: "times0001",  btn: "0.01 x 0.01",         txt: "Multiply decimal Fractions"},
    {typ: "times_div",  btn: "Division",         txt: "Division Times Tables"},
  ], []); 
  
  const [selectedType, setSelectedType] = useState('lin3');

  const [currentTypes, setCurrentTypes] = useState(types);

  const getRandomTask = () => {
    const filteredTasks = types.filter((task) => task.typ === selectedType);
    if (filteredTasks.length > 0) {
      const task = filteredTasks[0].typ;
      const processedTask = CreateTask(task);
      setCurrentTask(processedTask);
      setShowHelp(false);
      setShowResult(false);
      setShowExplainer(false);
    }
};

useEffect(() => {
  getRandomTask();
}, [selectedType]) // eslint-disable-line react-hooks/exhaustive-deps

const toggleShowHelp = () => {
setShowHelp(!showHelp);
setShowResult(false);
setShowExplainer(false);
};

const toggleShowResult = () => {
setShowResult(!showResult);
setShowHelp(false);
setShowExplainer(false);
};

const toggleShowExplainer = () => {
setShowExplainer(!showExplainer);
setShowHelp(false);
setShowResult(false);
};

const handleTypeSelection = (type) => {
  setSelectedType(type);
  //console.log("Selected Type:", selectedType, "Type neu: ", type);
  //console.log(currentTypes)
};

//test canvas
const x = "xx"
const y0 = 36
const y = y0.toString()
const z = (y0/2).toString()

return (

<div className={style.bg}>
  <div className={style.container}>
    <nav className={style.nav}>
      <div className={style.navdiv}>
        <a href="https://practicalpi.app" className={style.logo}>
          <img className={style.logoimg} src="./fav0.png" alt="practicalpi.app Logo" loading="lazy" />
          <span className={style.heading} translate="no">practicalPi.app</span>
        </a>
      </div>
    </nav>
     {/* Replaced: types.find(type) */}
    {/*<h4 id="typedesc" className={style.subheadingbold}>{selectedType ?  currentTypes.find((type) => type.typ === selectedType).txt : 'Practice Math and Boost Your Brainpower!'}</h4>*/}
    <h4 id="typedesc" className={style.subheadingbold}>{selectedType ? (currentTypes.find((type) => type.typ === selectedType)?.txt || 'Type not found') : 'Practice Math and Boost Your Brainpower!'}</h4>
    <main>
      <div className="buttons-container">
        <button title="New task"  type="button" className={style.btnadd}        onClick={getRandomTask}><AiOutlinePlus size={20} /></button>
        <button title="Help"      type="button" className={style.btnhelp}       onClick={toggleShowHelp}><AiOutlineQuestion size={20} /></button>
        <button title="Result"    type="button" className={style.btnresult}     onClick={toggleShowResult}><CgMathEqual size={20} /></button>
        <button title="Explainer" type="button" className={style.btnexplainer}  onClick={toggleShowExplainer}><AiOutlineBulb size={20} /></button>
      </div>

      {currentTask && (
        <Task
          key={currentTask.name}
          task={currentTask}
          showHelp={showHelp}
          toggleShowHelp={toggleShowHelp}
          showResult={showResult}
          toggleShowResult={toggleShowResult}
          showExplainer={showExplainer}
          toggleShowExplainer={toggleShowExplainer}
        />
      )}

      <div className="types">
        {/* Replaced: types.map(type) */}
        {currentTypes.map((type) => (
          <button  type="button"
            key={type.typ}
            onClick={() => handleTypeSelection(type.typ)}
            className={selectedType === type.typ ? style.taskbuttonactive : style.taskbuttons}
            title={type.txt}
          >
            {type.btn}
          </button>
        ))}
      </div>
            
    </main>

    {/*<Footer />*/}

    {/*<Auth />*/}

    {/*<div>
      <TriangleCanvas width={400} height={400} a={300} b={300} x={"A"} y={"B"} z={"C"} />
        </div>*/}


    <footer className="mt-12  text-center dark:bg-neutral-700 lg:text-left">
            <div className="p-0 text-center text-neutral-700 dark:text-neutral-200">
              <a href="https://www.youtube.com/@mathbydoing" target="_blank" rel="noreferrer">YouTube</a> | <a href="https://goo.gl/maps/Nt5q2Ag7vqkJsEgV7" target="_blank" rel="noreferrer">Contact</a>
            </div>
    </footer>
    
  </div>
</div>

);
}
export default App;