import { getRandomInt, multAlternatives } from "./_functions";
function addsub(){
    const v1 = 19
    const v2 = 38
    //Test
    //const v1 = 2
    //const v2 = 4

    let aufgabe, loesung, help, explainer, op1, op2, multiple, mult1, mult2, mult3

    //let plusminuscase = getRandomInt(4);
    let plusminuscase = 2 + getRandomInt(2); // 20240113 only a+(-b) and a-(-b) i.e. only with brackets

    switch(plusminuscase) { 
        
        /*
        case 0:  // 3 - 5, Zahl minus größere Zahl
            op1 = getRandomInt(v1);
            if (op1 === 0) op1++
            op2 = v1 + getRandomInt(v2) + 1; // v1, v2 = 20
            aufgabe = `\\[${op1}-${op2}=\\]`
            loesung = op1 - op2;
            help = `(${op1} - ${op2}) ist das Negative von (${op2} - ${op1})!`
            explainer= `Das Ergebnis ist kleiner als Null (< 0), weil du eine größere Zahl, ${op2}, von einer kleineren Zahl, ${op1}, abziehst.
            <br><br>Der umgekehrte Fall wäre ${op2} - ${op1} = ${op2 - op1}
            <br><br>Trick: siehe <kbd>&nbsp; ? &nbsp;</kbd>
            <br>${help}
            <br>Und wenn ${op2} - ${op1} = ${-loesung},
            <br>dann ist ${op1} - ${op2} = ${loesung}
            <br><br>Auf dem Zahlenstrahl: Pfeil nach rechts von Null nach ${op1}, dann Pfeil der Länge ${op2} von dort aus nach links - und du kommst bei ${op1 - op2} raus.
            `//!
        break;
        case 1:  // -3 - 5, minus neg Zahl minus neg Zahl
            op1 = getRandomInt(v1);
            if (op1 === 0) op1++
            op2 = getRandomInt(v2); // v1, v2 = 20
            if (op2 === 0) op2++
            aufgabe = `\\[-${op1}-${op2}=\\]`
            loesung = - op1 - op2;
            help = `(- ${op1} - ${op2}) ist das Negative von (+${op1} + ${op2})!`
            
            explainer = <div class="prose-lg">
            <p><strong>Key concept:</strong> <q>Subtracting the opposite is the same as adding.</q></p>
            <p>Is <kbd>(-{op2})</kbd> the opposite of <kbd>{op2}</kbd> ? It is!</p>
            <p>Thus, <strong>{op1} minus (-{op2})</strong> is the same as <strong>{op1} plus {op2}</strong>,</p>
            <p>which means <strong>{op1} - (-{op2}) = {op1} + {op2}</strong></p>
            </div>

        break;
        */
        
        case 2:  // ... +(-5)
            op1 = getRandomInt(v1) - getRandomInt(2*v1);
            if (op1 === 0) op1++
            op2 = getRandomInt(v2); // v1, v2 = 20
            if (op2 === 0) op2++
            aufgabe = `\\[${op1}+(-${op2})=\\]`
            loesung = op1 - op2;
            mult1 = -op1 - op2
            mult2 = op1 + op2
            mult3 = -op1 + op2

            help = `Don\'t let those pesky brackets intimidate you! Crush \' em like a pro and you\'ll be left with <kbd>${op1} - ${op2}</kbd>, which is just a friendly party of numbers. Click the button below to see if you can calculate the answer correctly.`
            
            explainer = <div class="prose-lg">
            <p><strong>Key concept:</strong> <q>Adding the opposite is the same as subtracting.</q></p>
            <p>Is <kbd>(-{op2})</kbd> the opposite of <kbd>{op2}</kbd> ? It is!</p>
            <p>Thus, <strong>{op1} plus (-{op2})</strong> is the same as <strong>{op1} minus {op2}</strong>,</p>
            <p>which means <strong>{op1} + (-{op2}) = {op1} - {op2}</strong></p>
            </div>
            
        break;
        case 3: // -(-5)
            op1 = getRandomInt(v1) - getRandomInt(2*v1);
            if (op1 === 0) op1++
            op2 = getRandomInt(v2); // v1, v2 = 20
            if (op2 === 0) op2++
            aufgabe = `\\[${op1}-(-${op2})=\\]`
            loesung = op1 + op2;
            mult1 = -op1 + op2
            mult2 = op1 - op2
            mult3 = -op1 - op2

            help = `Für das (-${op2}) gilt die Regel:
            <br>-(-x) = +x also:
            <br>${op1} - (-${op2}) = ${op1} + ${op2}`

            help = `Don\'t let those pesky brackets intimidate you! Crush \' em like a pro and you\'ll be left with <kbd>${op1} + ${op2}</kbd>, which is just a friendly party of numbers. Click the button below to see if you can calculate the answer correctly.`

            explainer = <div className="prose-lg">
            <p><strong>Key concept:</strong> <q>Subtracting the opposite is the same as adding.</q></p>
            <p>Is <kbd>(-{op2})</kbd> the opposite of <kbd>{op2}</kbd> ? It is!</p>
            <p>Thus, <strong>{op1} minus (-{op2})</strong> is the same as <strong>{op1} plus {op2}</strong>,</p>
            <p>which means <strong>{op1} - (-{op2}) = {op1} + {op2}</strong></p>
            </div>
        break;
        default:
    }

    //help = <div>TEST</div>
    //explainer = <div>Explainer TEST</div>

    let mults = multAlternatives([mult1, mult2, mult3], loesung) // remove elements that === loesung
    mults = [...new Set(mults)]; // remove duplilcates within mults
    mults = mults.map(element => [element, false]); // add true, false, false, false to elements
    const randomIndex = Math.floor(Math.random() * (mults.length + 1));
    mults.splice(randomIndex, 0, ...[[loesung, true]]); // add solution at random position
    multiple = mults

    loesung = `\\[${loesung}\\]`

    return [aufgabe, loesung, help, explainer, multiple];
}

export default addsub;


