import add from './components/add';
import addtxt from './components/addtxt';
import addsub from './components/addsub';
import frac from './components/frac';
import lin1 from './components/lin1';
import lin2 from './components/lin2';
import lin3 from './components/lin3';
import prop from './components/prop';
import prozent from './components/prozent';
import linfun from './components/linfun';
import quad from './components/quad';
import proba1 from './components/proba1';
import potenzen from './components/potenzen';
import times from './components/times';

function CreateTask(task) {
    const type = task // task is now only a string, only the type is needed, no other params
    let taskData = []
    switch(type){
        case "add":
            taskData = add()
        break;
        case "addtxt":
            taskData = addtxt()
        break;
        case "addsub": 
            taskData = addsub()
        break;
        case "frac":
            taskData = frac()
        break;
        case "lin1": 
           taskData = lin1()
        break;
        case "lin2": 
            taskData = lin2()
        break;
        case "lin3": 
            taskData = lin3()
        break;    
        case "prop": 
            taskData = prop()
        break; 
        case "prozent": 
            taskData = prozent()
        break; 
        case "linfun": 
            taskData = linfun()
        break;   
        case "quad": 
            taskData = quad()
            //console.log("quad!")
        break;      
        case "proba1": 
            taskData = proba1()
        break; 
        case "potenzen": 
            taskData = potenzen()
        break; 
        case "times": 
            taskData = times()
        break; 
        default: 
            taskData = add()
    }
    const processedTask = {
        type: type,
        text: `${taskData[0]}`,
        answer: `${taskData[1]}`,
        //help: `${taskData[2]}`,
        help: taskData[2],
        explainer: taskData[3],
        multiple: taskData[4]
    };
    //console.log("CreateTask")
    //console.log(taskData)
    //console.log(processedTask)
    return processedTask;
}
    
export default CreateTask;