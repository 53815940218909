import { useState } from 'react';
//import { createPortal } from 'react-dom';
import TriangleHowTo from './TriangleHowTo.js';
import { AiOutlineInfo } from "react-icons/ai";
import { AiFillCloseSquare } from "react-icons/ai";
const style={
    rounded: `rounded-lg`,
    inv: `text-white bg-black`,
    red: `text-white bg-red-600 rounded-md`
}

export default function TrianglePortal() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {!showModal && <button onClick={() => setShowModal(true)}>
        {/*Show modal using a portal*/}
        <AiOutlineInfo size={20} className={style.red}  title="How to use the formula triangle" />
      </button>}
      {showModal && <button onClick={() => setShowModal(false)}>
        {/*Show modal using a portal*/}
        <AiFillCloseSquare size={28} className={style.rounded}  title="Close" />
      </button>}
      {showModal &&
        <TriangleHowTo onClose={() => setShowModal(false)} />
      }
      {/*showModal && createPortal(
        <TriangleHowTo onClose={() => setShowModal(false)} />,
        document.body
      )*/}
    </>
  );
}
