import React, { useRef, useEffect } from 'react';

import "../App.css";

const TriangleCanvas = ({ width, height, a, b, x, y, z }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, width, height); // Clear the canvas

      // Calculate the coordinates of the triangle
      const x0 = width / 2 - b / 2;
      const y0 = height - 5; // Start a little above the bottom
      const x1 = x0 + b;
      const y1 = height - 5;
      const x2 = width / 2;
      const y2 = height - a - 5;

      // Draw the triangle
      ctx.beginPath();
      ctx.moveTo(x0, y0);
      ctx.lineTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.closePath();

      // inner items
      ctx.moveTo(x0+b/3, y2+0.54*b);
      ctx.lineTo(x0+2*b/3, y2+0.54*b);
      ctx.fillRect(x0+0.95*b/2,y0-b/5,10,10); // fill in the pixel at (10,10)
      ctx.closePath();

      // the outline
      ctx.lineWidth = 6;
      ctx.strokeStyle = '#000000'; // Black outline
      ctx.stroke();

      // Text settings
      ctx.font = '48px Arial';
      ctx.fillStyle = '#000000'; // Black text
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      // Place the letters
      // Letter a
      //ctx.fillText('a', x2, y0 - (y0 - y2) / 2);
      //ctx.fillText('a', x2, y2);
      ctx.fillText(x, x2, y2+b/3);

      // Letter b
      //ctx.fillText('b', (x0 + x2) / 2, y0 + 20); // Adjust position as needed
      //ctx.fillText('b', x0, y0);
      ctx.fillText(y, x0+b/4 + 0.05*b , y0-b/6);
      // Letter c - Adjusted position for visibility
      //ctx.fillText('c', (x1 + x2) / 2, y0 + 20); // Adjust position as needed
      ctx.fillText(z, x0+3*b/4 - 0.05*b, y0-b/6);

    }
  }, [width, height, a, b, x, y, z]);

  return (
      <canvas ref={canvasRef} width={width} height={height} />
  )
};

export default TriangleCanvas;
