function getRandomInt(n) { 
    return Math.floor(Math.random() * n);
}

function brac(n) {
  return n < 0 ? n = "("+n+")" : n.toString()
}

function oneoftwo(x1, x2) {
  return Math.random() < 0.5 ? x1 : x2
}

function getlowestfraction(x0,format) {
  let eps = 1.0E-15;
  let h, h1, h2, k, k1, k2, a, x;
  //let format = "jax"
  x = x0;
  a = Math.floor(x);
  h1 = 1;
  k1 = 0;
  h = a;
  k = 1;
  while (x-a > eps*k*k) {
      x = 1/(x-a);
      a = Math.floor(x);
      h2 = h1; h1 = h;
      k2 = k1; k1 = k;
      h = h2 + a*h1;
      k = k2 + a*k1;
  }
  // return h + "/" + k;
 
     // (format === "jax") ? `\\[\\frac{${h}}{${k}}\\]` : h + "/" + k
  if (format === "jax"){
      return k === 1 ? `\\({${h}}\\)` : `\\(\\frac{${h}}{${k}}\\)`
      //return `\\[\\frac{${h}}{${k}}\\]`
  } else if (format === "text") {
      return `${h} geteilt durch ${k}`;
  } else {
      return h + "/" + k;
  }
}


function multAlternatives(arr, variable) {
  // Filter the array to keep only the elements that are not equal to the variable
  const filteredArray = arr.filter(element => element !== variable);
  return filteredArray;
}

const copyTextToClipboard = (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    // Use the Navigator Clipboard API when available
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text successfully copied to the clipboard');
    }).catch((err) => {
      console.error('Failed to copy text to clipboard', err);
    });
  } else {
    // Fallback for older browsers
    try {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();
      const successful = document.execCommand('copy');
      document.body.removeChild(textarea);
      if (successful) {
        console.log('Fallback: Text successfully copied to clipboard');
      } else {
        console.error('Fallback: Failed to copy text to clipboard');
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  }
};

const functions = {};
  
functions.getRandomInt = getRandomInt;
functions.brac = brac;
functions.oneoftwo = oneoftwo;
functions.getlowestfraction = getlowestfraction;
functions.multAlternatives = multAlternatives;
functions.copyTextToClipboard = copyTextToClipboard;

module.exports = functions;