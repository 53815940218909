function frac(){
    let aufgabe, loesung, help, explainer
    let num, den
    const divisors = [2,3,5,7,9]
    const randomDivisor = divisors[Math.floor(Math.random() * divisors.length)];
    num = getRandomInt2(5, randomDivisor);
    den = getRandomInt2(8, randomDivisor);

    // den = 1 //Test
    // num = 20
    // den = 10
    if(num === den) den = den + randomDivisor;
    aufgabe = `\\[\\frac{${num}}{${den}}\\]`;
    loesung = getlowestfraction(num/den, "jax");
    let numdiv = findDivisors(num).replaceAll(",", "  ") + " and " + num;
    let dendiv = findDivisors(den).replaceAll(",", "  ") + " and " + den;
    //let dendiv = findDivisors(den);
    //let comdiv = findCommonElements(numdiv, dendiv);
    //console.log(numdiv, dendiv, comdiv)
    let gcd = gcdTwoNumbers(num, den)
    help = `\\[\\frac{${num}}{${den}} = \\frac{${num}:${gcd}}{${den}:${gcd}}\\]`
    
    
    explainer = `
    <dl>
    <dt>Step 1: List the factors of ${num}</dt>
    <dd>${numdiv}</dd>
    <dt>Step 2: List the factors of ${den}</dt>
    <dd>${dendiv}</dd>
    <dt>Compare the two lists: what is the greatest common factor?</dt>
    <dd>The greatest common factor of ${num} and ${den} is ${gcd}</dd>
    </dl>`//!
    



    explainer = explainer + `<br>You might notice some sections are currently empty - that's because we're busy crafting new and exciting content for you! Our team is working diligently to bring you comprehensive help and in-depth explainer information. These features will be available shortly, enriching your learning experience even further. <br>
    <br>Stay tuned for these updates, and thank you for your patience as we strive to make practicalPi even more helpful and informative for all our users!`


    //    <br><br>Wenn du den Zähler ${num} und den Nenner ${den} durch den ggT, also ${gcd} teilst, erhältst du den gekürzten Bruch.
    // ${gcd === den ? "<br><br>(Da der Nenner des Ergebnisbruchs = 1 ist, hat der Ergebnisbruch einfach den Wert des Zählers.)" : ""}




    return [aufgabe, loesung, help, explainer];
}
export default frac;

function getRandomInt2(max, n) { // n = Teiler = 2, 3, 5, 7
    return Math.floor(Math.random() * max) * n + n; // +n die Null ausschl.
  }

function getlowestfraction(x0,format) {
    let eps = 1.0E-15;
    let h, h1, h2, k, k1, k2, a, x;
    //let format = "jax"
    x = x0;
    a = Math.floor(x);
    h1 = 1;
    k1 = 0;
    h = a;
    k = 1;
    while (x-a > eps*k*k) {
        x = 1/(x-a);
        a = Math.floor(x);
        h2 = h1; h1 = h;
        k2 = k1; k1 = k;
        h = h2 + a*h1;
        k = k2 + a*k1;
    }
    // return h + "/" + k;
   
       // (format === "jax") ? `\\[\\frac{${h}}{${k}}\\]` : h + "/" + k
    if (format === "jax"){
        return k === 1 ? `\\[{${h}}\\]` : `\\[\\frac{${h}}{${k}}\\]`
        //return `\\[\\frac{${h}}{${k}}\\]`
    } else if (format === "text") {
        return `${h} divided by ${k}`;
    } else {
        return h + "/" + k;
    }
}

function findDivisors(integer) { //https://dev.to/cesar__dlr/32-find-the-divisors-codewars-kata-7-kyu-5f7n
    let r = []
    for(let i = 2; i<integer; i++){
      if(integer%i === 0) r.push(i)
    }
    let res = r.length !== 0 ? `The factors of ${integer} are ${r}` : `${integer} is prime`
    return res
  }

  /*
  function findCommonElements(numd, dend) { ////////////////////// check!
    let commonElements = [];
    for (let i = 0; i < numd.length; i++) {
        if (numd[i] in dend) {
            commonElements.push(numd[i]);
        }
    }
    console.log(numd, dend, commonElements)
    return commonElements.join(",")
  }
  */

  function gcdTwoNumbers(x, y) {
    if ((typeof x !== 'number') || (typeof y !== 'number')) 
      return false;
    x = Math.abs(x);
    y = Math.abs(y);
    while(y) {
      var t = y;
      y = x % y;
      x = t;
    }
    return x;
  }

  /*
  function primeFactors(num) {
    function is_prime(num) {
      for (let i = 2; i <= Math.sqrt(num); i++)
      {
        if (num % i === 0) return false;
      }
      return true;
    }
    const result = [];
    for (let i = 2; i <= num; i++)
    {
      while (is_prime(i) && num % i === 0) 
      {
        if (!result.includes(i)) result.push(i);
        num /= i;
      }
    }
    return result;
  }
  */