import { getRandomInt, multAlternatives } from "./_functions";

function add(){    
    let op, aufgabe, loesung, help, explainer, multiple, mult1, mult2, mult3
    let op1 = getRandomInt(20) - 10;
    if (op1 === 0) op1++
    let op2 = getRandomInt(30) + 1;  
    if (op1 === op2) op2++
    if (op1 === 0) op1 = 4;
    if (op2 === 0) op2 = 5;

    if (Math.random() < 0.5){
        op = "+";
        loesung = op1 + op2;
        mult1 = -op1 + op2;
        mult2 = op1 - op2;
        mult3 = -op1 - op2;
    } else {
        op = "-";
        loesung = op1 - op2;
        mult1 = -op1 - op2;
        mult2 = op1 + op2;
        mult3 = -op1 + op2;
    }
    
    const loesungtxt = loesung
    
    if (op2 < 0) {
        aufgabe = `\\[${op1}${op}(${op2})=\\]`
    } else {
        aufgabe = `\\[${op1}${op}${op2}=\\]`
    }
    
    const zahlenstrahlop2 = ((op2<0 && op==="+") || (op2>0 && op==="-")) ? "left" : "right" 

    let op22 = op === "+" ? op2 : -op2;
    help = `${op1}/a/${op22}`

    explainer = <div className="prose-lg">
    <p>Get ready for your trip on the number line, where we'll add and subtract like a pro. As a pro, draw your own number line or even imagine a number line.</p>
    <ol>
        <li>Start at Zero (0). This is like your home base.</li>
        <li>Move {Math.abs(op1)} {Math.abs(op1)===1?"unit":"units"} to the {op1<0 ? "left (minus)" : "right (plus)" }. This means you're going away from Zero, towards your first goal.</li>
        <li>Now you have made it to the first goal ({op1}) and you start out from there. Zoom {Math.abs(op2)} {Math.abs(op2)===1?"unit":"units"} to the {zahlenstrahlop2}.</li>
        <li>Where are you now?</li>
    </ol>
    </div>
    
    let mults = multAlternatives([mult1, mult2, mult3], loesung) // remove elements that === loesung
    mults = [...new Set(mults)]; // remove duplilcates within mults
    mults = mults.map(element => [element, false]); // add true, false, false, false to elements
    const randomIndex = Math.floor(Math.random() * (mults.length + 1));
    mults.splice(randomIndex, 0, ...[[loesung, true]]); // add solution at random position
    multiple = mults

    loesung = `\\[${loesung}\\]`

    return [aufgabe, loesung, help, explainer, multiple];
}

export default add;

