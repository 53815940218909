import { getRandomInt } from "./_functions";

function times(filter){    

    let aufgabe, loesung, help, explainer
    
    let op1 = 2 + getRandomInt(8)
    let op2 = 2 + getRandomInt(8)
    //let op10, op20, mult1, mult2, mult1fac, mult2fac, mult12fac, loesungval
    let loesungval

    loesungval = op1*op2
    loesung = `\\[${op1} \\cdot ${op2} = ${loesungval}\\]`
   
    help = `
    (Graphik folgt)
    `//!

    aufgabe = `\\[${op1} \\cdot ${op2} = ?\\]`
    loesungval = op1*op2
    loesung = `\\[${op1} \\cdot ${op2} = ${loesungval}\\]`
    help = `${op1}///${op2}`
    explainer =
    op1 === 2 ?
    `<h3><q>Multiplying a number by 2 is simply adding the number to itself.</q></h3>
    \\[2 \\cdot ${op2} = ${op2} + ${op2} = ${loesungval}\\]`
    :
    op2 === 2 ?
    `<h3><q>Multiplying a number by 2 is simply adding the number to itself.</q></h3>
    \\[${op1} \\cdot 2 = ${op1} + ${op1} = ${loesungval}\\]`
    :
    op1 === 5 ?
    `<h3><q>A super trick for multiplying by 5 is to add a zero and then halve the result.</q></h3>
    \\[10 \\cdot ${op2} = ${10*op2}\\] \\[5 \\cdot ${op2} = \\frac{${10*op2}}{2} = ${loesungval}\\]`
    :
    op2 === 5 ?
    `<h3><q>A super trick for multiplying by 5 is to add a zero and then halve the result.</q></h3>
    \\[${op1} \\cdot 10 = ${10*op1}\\]\\[${op1} \\cdot 5 = \\frac{${10*op1}}{2} = ${loesungval}\\]`
    :
    op1 === 9 ?
    `<h3><q>A quick trick for multiplying by 9 is to multiply the number by 10 and then subtract the number again.</q></h3>
    \\[10 \\cdot ${op2} = ${10*op2}\\] \\[9 \\cdot ${op2} = ${10*op2} - ${op2} = ${loesungval}\\]`
    :
    op2 === 9 ? 
    `<h3><q>A quick trick for multiplying by 9 is to multiply the number by 10 and then subtract the number again.</q></h3>
    \\[${op1} \\cdot 10 = ${10*op1}\\] \\[${op1} \\cdot 9 = ${10*op1} - ${op1} = ${loesungval}\\]`
    : 
    op1 === 4 ?
    `<h3><q>Multiplying by 4 is doubling the number and then doubling the result again.</q></h3>
    \\[4 \\cdot ${op2} = 2 \\cdot 2 \\cdot ${op2}\\] \\[2 \\cdot ${2*op2} = ${loesungval}\\]`
    :
    op2 === 4 ? 
    `<h3><q>Multiplying by 4 is doubling the number and then doubling the result again.</q></h3>
    \\[${op1} \\cdot 4 = ${op1} \\cdot 2 \\cdot 2\\] \\[${op1*2} \\cdot 2 = ${loesungval}\\]`
    : 
    op1 === 3 ?
    `<h3><q>Multiplying by 3 is doubling the number and then adding it to itself again.</q></h3>
    \\[3 \\cdot ${op2} = ${op2} + ${op2} + ${op2} = ${2*op2} + ${op2} = ${loesungval}\\]`
    :
    op2 === 3 ?
    `<h3><q>Multiplying by 3 is doubling the number and then adding it to itself again.</q></h3>
    \\[${op1} \\cdot 3 = ${op1} + ${op1} + ${op1} = ${2*op1} + ${op1} = ${loesungval}\\]`
    :
    op1 === 8 ?
    `<h3><q>Multiplying by 8 is simple: double the number three times in a row. This is like multiplying by the number two three times.</q></h3>
    \\[8 \\cdot ${op2} = ${op2} \\cdot 2 \\cdot 2 \\cdot 2 = ${loesungval}\\]`
    :
    op2 === 8 ? 
    `<h3><q>Multiplying by 8 is simple: double the number three times in a row. This is like multiplying by the number two three times.</q></h3>
    \\[${op1} \\cdot 8 = ${op1} \\cdot 2 \\cdot 2 \\cdot 2 = ${loesungval}\\]`
    : 
    op1 === 6 ?
    `<h3><q>Multiplying by 6 is easy: double the number and then double the result again. Or, you can double the number twice in a row.</q></h3>
    \\[6 \\cdot ${op2} = ${op2} \\cdot 3 \\cdot 2 = ${3*op2} \\cdot 2 = ${loesungval}\\]`
    :
    op2 === 6 ? 
    `<h3><q>Multiplying by 6 is easy: double the number and then double the result again. Or, you can double the number twice in a row.</q></h3>
    \\[${op1} \\cdot 6 = ${op1} \\cdot 3 \\cdot 2 = ${3*op1} \\cdot 2 = ${loesungval}\\]`
    : 
    "";
    
    
 
    
    

    return [aufgabe,loesung,help,explainer]
 }

export default times;


  

