import React, { useState, useEffect } from "react"; // neu 0916
import DOMPurify from 'dompurify';
import { MathJaxContext, MathJax } from "better-react-mathjax";
//import TextToSpeech from './TextToSpeech';
import Rectangle from "./components/Rectangle";
import NumberLineAddition from "./components/NumberLineAddition";
import MultipleChoice from "./MultipleChoice";
import { calculateMultiplicationVisualization } from './components/_MultiplicationVisualization';
import TriangleCanvas from './components/TriangleCanvas'; 
//import { AiOutlineInfo } from "react-icons/ai";
import TrianglePortal from './components/TrianglePortal';

const style={
    //taskheader:`text-center prose prose-lg`,
    taskheader:`text-center prose prose-2xl`,
    //taskheader: `mb-4 text-4xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl`,
    tasktext: `prose prose-sm`,
    helptext: `prose prose-lg border-solid border-2 border-red-600 px-2 py-2 my-4 mb-2 rounded-md`,
    //speechtext: `prose prose-lg`,
    resulttext: `prose prose-lg`,
    explainertext: `prose prose-lg border-solid border-2 border-gray-400 px-2 my-2 rounded-md`,
    subheader: `prose prose-lg`,
    subheader2: `prose prose-2xl`,
    subheader3: `prose prose-2xl font-black`,
    //bgyellow: `bg-yellow-50`,
    //btnspeak: `button text-white bg-gradient-to-r from-black-400 via-black-500 to-black-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-1 text-center mt-2 mr-2 mb-2 mt-3 text-xl`,
    //center: `content-center`
    center: `flex justify-center w-full`,
    red: `text-white bg-red-600 rounded-md`

    //task: `h-6`
}


function Task({ task, showHelp, showResult, showExplainer }) {
    const { type, text, help, answer, explainer, multiple } = task;
    //console.log(multiple)
    const headerclass = false;
    const headerClassName = headerclass ? style[headerclass] : style.taskheader;
    const { helptxt, aa, bb, isrectangle, isnumberline } = calculateMultiplicationVisualization(help, type);
    const img = `img/${aa}x${bb}.jpg`
    const sanitizedExplainer = DOMPurify.sanitize(explainer);
    const isReactElement = (element) => {
        return typeof element === 'object' && element !== null;
    }
    //console.log(explainer)
    //const [showModal, setShowModal] = useState(false);
    return (
    <MathJaxContext>
    <div className={style.taskxxx}>
        <div className={style.task}><h3 className={headerClassName || style.taskheader}><MathJax inline dynamic><span  dangerouslySetInnerHTML={{ __html: text }} /></MathJax></h3></div>
        {showHelp && type === "times" && <img src={img} />}
        {showHelp && !isReactElement(help) && <MathJax inline dynamic><div className={style.helptext} dangerouslySetInnerHTML={{ __html: helptxt }} /></MathJax>}
        {showHelp && isReactElement(help)  && <MathJax dynamic><div className={style.helptext}>{help}</div></MathJax>}
        {showResult && <h3 className={headerClassName || style.taskheader}><MathJax inline dynamic><span  dangerouslySetInnerHTML={{ __html: answer }} /></MathJax></h3>}
        {showExplainer && !isReactElement(explainer) && !Array.isArray(explainer) && <MathJax inline dynamic><div className={style.explainertext} dangerouslySetInnerHTML={{ __html: sanitizedExplainer }} /></MathJax>}
        {showExplainer && isReactElement(explainer) && !Array.isArray(explainer)  && <MathJax dynamic><div className={style.explainertext}>{explainer}</div></MathJax>}
        {showExplainer && Array.isArray(explainer) && 
            <div className={style.center}>
                <TriangleCanvas width={400} height={300} a={280} b={300} x={explainer[0]} y={explainer[1]} z={explainer[2]} className={style.center} />
            </div>}
        {showExplainer && Array.isArray(explainer) && <TrianglePortal />}   
        {showHelp && type === "times" && isrectangle && ( <Rectangle a={aa} b={bb} />)}
        {showHelp && isnumberline && ( <NumberLineAddition num1={aa} num2={bb} /> )}
        {showHelp && multiple && <MultipleChoice choices={multiple} />}
    </div>
    </MathJaxContext>
            
    );
}
export default Task;