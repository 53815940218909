import { AiFillCloseSquare } from "react-icons/ai";
const style={
    rounded: `rounded-lg`,
    inv: `text-white bg-black`
}

export default function TriangleHowTo({ onClose }) {
    return (
      <article class="prose lg:prose-xl">
      <div className="modal">
        <h4>Unlock Quick Solutions with Formula Triangles!</h4>
        <blockquote>See how you tackle equations in just under four minutes—a skill that'll make math and physics problems a breeze throughout school. Ready to learn? Let’s dive in with these three common equation types.</blockquote>
        
        <p><b>Type 1</b> <kbd>x / a = b</kbd>. For instance, <kbd>x / 4 = 3</kbd>.</p>
        <div class="text-center">
          <figure className="figure">
            <img src="./img/formula-triangle/type-1-read.png" className="figure-img img-fluid rounded" alt="x / a = b, z.B. x / 4 = 3 &nbsp;&rArr;&nbsp; x = 4 &middot; 3" />
            <figcaption className="figure-caption">x / a = b, z.B. x / 4 = 3 &nbsp;&rArr;&nbsp; x = 4 &middot; 3</figcaption>
          </figure>
        </div>
        <p>Here's a quick trick: Imagine covering the 'x' in the formula triangle. What's left? The equation x = 4 &middot; 3. Just like that, x equals 12!</p>
        
        <p><b>Type 2</b> <kbd> a / x = b</kbd>. Like <kbd>12 / x = 3</kbd>.</p>
        <div class="text-center">
          <figure className="figure">
            <img src="./img/formula-triangle/type-2-read.png" className="figure-img img-fluid rounded" alt="a / x = b, z.B. 12 / x = 3 &nbsp;&rArr;&nbsp; x = 12 / 3" />
            <figcaption className="figure-caption">a / x = b, z.B. 12 / x = 3 &nbsp;&rArr;&nbsp; x = 12 / 3</figcaption>
          </figure>
        </div>
        <p>Cover 'x' in the triangle, and it's clear as day: x = 12/3. You've got it, x is 4!</p>

        <p><b>Type 3</b> <kbd>a &middot; x = b</kbd>. Consider <kbd>4 &middot; x = 12</kbd>.</p>
        <div class="text-center">
          <figure className="figure">
            <img src="./img/formula-triangle/type-3-read.png" className="figure-img img-fluid rounded" alt="a &middot; x = b, z.B. 4 &middot; x = 12 &nbsp;&rArr;&nbsp; x = 12 / 4" />
            <figcaption className="figure-caption">a &middot; x = b, z.B. 4 &middot; x = 12 &nbsp;&rArr;&nbsp; x = 12 / 4</figcaption>
          </figure>
        </div>
        <p>When 'x' is covered, it's simple: x = 12/4. And there's your answer, x is 3.</p>

        <blockquote>Now, let's craft these triangles from your equation, with two straightforward rules:</blockquote>

        <p><b>Type 1</b> <kbd>x / a = b</kbd>. Take <kbd>x / 4 = 3</kbd> for example.</p>
        <div class="text-center">
          <figure className="figure">
            <img src="./img/formula-triangle/type-1-build.png" className="figure-img img-fluid rounded" alt="x / 4 = 3 &nbsp;&rArr;&nbsp; &quot;x&quot; is top and goes to top" />
            <figcaption className="figure-caption">x / 4 = 3 &nbsp;&rArr;&nbsp; &quot;x&quot; is top and goes to top</figcaption>
          </figure>
        </div>
        <p>Remember, “Top goes to top”: place 'x' at the triangle’s peak!</p>

        <p><b>Type 2</b> <kbd>a / x = b</kbd>. Like <kbd>12 / x = 3</kbd>.</p>
        <div class="text-center">
          <figure className="figure">
            <img src="./img/formula-triangle/type-2-build.png" className="figure-img img-fluid rounded" alt="12 / x = 3 &nbsp;&rArr;&nbsp; &quot;12&quot; is top and goes to top" />
            <figcaption className="figure-caption">12 / x = 3 &nbsp;&rArr;&nbsp; &quot;12&quot; is top and goes to top</figcaption>
          </figure>
        </div>
        <p>The same rule applies: “Top goes to top” puts the '12' on top.</p>

        <p><b>Type 3</b> <kbd>a &middot; x = b</kbd>. With <kbd>4 &middot; x = 12</kbd>.</p>
        <div class="text-center">
          <figure className="figure">
            <img src="./img/formula-triangle/type-3-build.png" className="figure-img img-fluid rounded" alt="4 &middot; x = 12 &nbsp;&rArr;&nbsp; &quot;12&quot; is single and goes to top" />
            <figcaption className="figure-caption">4 &middot; x = 12 &nbsp;&rArr;&nbsp; &quot;12&quot; is single and goes to top</figcaption>
          </figure>
        </div>
        <p>Here's the twist: “Single goes to top” means the standalone number, '12', sits atop the triangle.</p>

        <blockquote>Lastly, why should you care? Formula triangles aren't just for now—they're your secret weapon for a slew of physics formulas that'll cross your path, like v = d/t, a = v/t, F=m&middot;a, and many more. Embrace them and make the complex simple!</blockquote>
        <button onClick={onClose}><AiFillCloseSquare size={28} className={style.rounded}  title="Close" /></button>
      </div>
      </article>
    );
  }