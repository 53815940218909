import { getRandomInt, brac, oneoftwo, getlowestfraction, multAlternatives } from "./_functions";
function lin3() {
    let aufgabe, loesung, help, explainer, explainer1, explainer2, loesung0, multiple, mult1, mult2
    const min = 2
    const max = 20
    let a = getRandomInt(max-min) + min; // > 0
    let b = getRandomInt(max-min) + min; // > 0
    //console.log(a, b)
    //console.log(oneoftwo(12, "AB"))
    if (b === a) b++
    const casex = getRandomInt(8)
    //const casex = 8
    // default:
    explainer = `You might notice some sections are currently empty - that's because we're busy crafting new and exciting content for you! Our team is working diligently to bring you comprehensive help and in-depth explainer information. These features will be available shortly, enriching your learning experience even further. <br>
    <br>Stay tuned for these updates, and thank you for your patience as we strive to make practicalPi even more helpful and informative for all our users!`

    //explainer2a = ""
    switch(casex) {
        case 0:  // a * x = b
            aufgabe = `\\[${a} \\cdot x = ${b}\\]`                       // a * x = b
            loesung = `\\[x = \\frac{${b}}{${a}} = ${(b/a).toFixed(2)}\\]`;
            loesung0 = (b/a).toFixed(2);
            mult1 = (a/b).toFixed(2);
            mult2 = a*b;
            help = help012(a, b)
            explainer = [b, a, "x"]
        break;
        case 1: // - a * x = b
            aufgabe = `\\[${-a} \\cdot x = ${b}\\]`                       
            loesung = `\\[x = - \\frac{${b}}{${a}} = ${(-b/a).toFixed(2)}\\]`;
            loesung0 = (-b/a).toFixed(2);
            mult1 = (-a/b).toFixed(2);
            mult2 = -a*b;
            help = help012(-a, b)
            explainer = [b, -a, "x"]
        break;
        case 2: // a * x = -b
            aufgabe = `\\[${a} \\cdot x = ${-b}\\]`                       // a * x = -b
            loesung = `\\[x = - \\frac{${b}}{${a}} = ${(-b/a).toFixed(2)}\\]`;
            loesung0 = (-b/a).toFixed(2);
            mult1 = (-a/b).toFixed(2);
            mult2 = -a*b;
            help = help012(a, -b)
            explainer = [-b, a, "x"]
        break;
        case 3: // a / x = b
            aufgabe = `\\[\\frac{${a}}{x} = ${b}\\]`                       //  a / x = b
            loesung = `\\[x = \\frac{${a}}{${b}} = ${(a/b).toFixed(2)}\\]`;
            loesung0 = (a/b).toFixed(2);
            mult1 = (b/a).toFixed(2);
            mult2 = a*b;
            help = help345(a, b)
            explainer = [a, "x", b]
        break;
        case 4: // -a / x = b
            aufgabe = `\\[\\frac{${-a}}{x} = ${b}\\]`                       //  - a / x = b
            loesung = `\\[x = \\frac{${-a}}{${b}} = ${(-a/b).toFixed(2)}\\]`;
            loesung0 = (-a/b).toFixed(2);
            mult1 = (-b/a).toFixed(2);
            mult2 = -a*b;
            help = help345(-a, b)
            explainer = [-a, "x", b]
        break;
        case 5: // a / x = -b
            aufgabe = `\\[\\frac{${a}}{x} = ${-b}\\]`                       //  a / x = - b
            loesung = `\\[x = \\frac{${a}}{${-b}} = ${(-a/b).toFixed(2)}\\]`;
            loesung0 = (-a/b).toFixed(2);
            mult1 = (-b/a).toFixed(2);
            mult2 = -a*b;
            help = help345(a, -b)
            explainer = [a, "x", -b]
        break;
        case 6: // x/a = b
            aufgabe = `\\[\\frac{x}{${a}} = ${b}\\]`                       //  x / a = b
            loesung = `\\[x = ${(a*b)}\\]`;
            loesung0 = a*b;
            mult1 = (a/b).toFixed(2);
            mult2 = (b/a).toFixed(2);
            help = help68(a, b)
            explainer = ["x", a, b]
        break;
        case 7: //  x / (-a) = b
            aufgabe = `\\[-\\frac{x}{${a}} = ${b}\\]`                       
            loesung = `\\[x = ${(-a*b)}\\]`;
            loesung0 = -a*b;
            mult1 = (-a/b).toFixed(2);
            mult2 = (-b/a).toFixed(2);
            help = help7(a, b);
            explainer = ["x", -a, b]
        break;
        case 8: // x / a = -b
            aufgabe = `\\[\\frac{x}{${a}} = ${-b}\\]`                       //  x / a = -b
            loesung = `\\[x = ${(-a*b)}\\]`;
            loesung0 = -a*b;
            mult1 = (-a/b).toFixed(2);
            mult2 = (-b/a).toFixed(2);
            help = help68(a, -b)
            explainer = ["x", a, -b]
        break;

        default:

    }
    /*
    if (explainer2a && Math.random()<.5){
        explainer = explainer2a;
    } else {
        explainer = explainer1 + explainer2;
    }
    */

    let mults = multAlternatives([mult1, mult2], loesung0) // remove elements that === loesung
    mults = [...new Set(mults)]; // remove duplilcates within mults
    mults = mults.map(element => [element, false]); // add true, false, false, false to elements
    const randomIndex = Math.floor(Math.random() * (mults.length + 1));
    mults.splice(randomIndex, 0, ...[[loesung0, true]]); // add solution at random position
    multiple = mults

    return [aufgabe, loesung, help, explainer, multiple];
}

export default lin3;

function help012(a,b){
 return(
    `Divide both sides of the equation by \\( ${brac(a)} \\)
    \\[\\frac{${a} \\cdot x}{${a}} = \\frac{${b}}{${a}}\\]
    and then simplify the resulting fraction:
    \\[\\frac{${a} \\cdot x}{${a}} = x\\]
    Now, calculating \\( x \\) is a breeze:
    \\[x = \\frac{${b}}{${a}}\\]
    Like a pro, don't waste time by dividing the equation and then simplifying the fraction. Instead, simplify the <i>equation</i> directly:
    \\[${a} \\cdot x = ${b} \\Longleftrightarrow x = \\frac{${b}}{${a}}\\]
    `//
 )
}
function help345(a,b){
    return(
       `Multiply both sides of the equation by \\( x \\):
       \\[\\frac{${a}}{x} \\cdot x = ${b} \\cdot x\\]
       Simplify the expression and get
       \\[${a} = ${b} \\cdot x\\]
       Next, divide both sides of the equation by \\( ${brac(b)} \\):
       \\[\\frac{${a}}{${b}} = x\\]
       Now, calculating \\( x \\) is a breeze!<br>
       <br>
       And here is a pro trick to simplify the <i>equation</i> directly: just let the \\( x \\) and the \\( ${brac(b)} \\) swap places!
       \\[\\frac{${a}}{x} = ${b} \\Longleftrightarrow \\frac{${a}}{${b}} = x \\]
       `//
    )
   }
   function help68(a,b){
    return(
       `Multiply both sides of the equation by \\( ${brac(a)} \\)
       \\[\\frac{x}{${a}} \\cdot ${brac(a)} = ${b} \\cdot ${a}\\]
       Simplify the expression
       \\[x = ${b} \\cdot ${a}\\]
       Now, calculating \\( x \\) is a breeze!
       `//
    )
   }
   function help7(a,b){
    return(
       `Multiply both sides of the equation by \\( ${a} \\)
       \\[-\\frac{x}{${a}} \\cdot ${a} = ${b} \\cdot ${a}\\]
       Simplify the expression
       \\[-x = ${b} \\cdot ${a}\\]
       \\[x = - ${b} \\cdot ${a}\\]
       Now, calculating \\( x \\) is a breeze!
       `//
    )
   }

/*
function getRandomInt(n) { 
    return Math.floor(Math.random() * n);
}
*/

