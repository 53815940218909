import { getRandomInt, multAlternatives, copyTextToClipboard } from "./_functions";
function lin1() {
    let aufgabe, aufgabe0, gauthmath, googlemath, loesung, help, explainer, multiple, mult1, mult2, mult3

    let a = getRandomInt(30) + 1; // > 0
    let b = getRandomInt(20) - 10; 
    if (b === 0) b=21
    const casex = getRandomInt(8)
    //const casex = 7
   
    switch(casex) {
        case 0: // x + a = b                     
            aufgabe = `\\[x + ${a} = ${b}\\]`   
            aufgabe0 = `x + ${a} = ${b}`
            gauthmath = `x%2B${a}%3D${b}`                
            loesung = b-a;
            mult1 = -b-a
            mult2 = b+a
            mult3 = -b+a
            help = help0(a, b)
        break;
        case 1: // x - a = b             
            aufgabe = `\\[x - ${a} = ${b}\\]`
            aufgabe0 = `x - ${a} = ${b}`
            gauthmath = `x-${a}%3D${b}`
            loesung = b+a;
            mult1 = -b+a
            mult2 = b-a
            mult3 = -b-a
            help = help1(a, b)
        break;
        case 2: // -x + a = b                   
            aufgabe = `\\[-x + ${a} = ${b}\\]` 
            aufgabe0 = `-x + ${a} = ${b}`
            gauthmath = `-x%2B${a}%3D${b}`
            loesung = a-b
            mult1 = -a-b
            mult2 = a+b
            mult3 = -a+b
            help = help2(a, b)
        break;
        case 3: // -x - a = b                     
            aufgabe = `\\[-x - ${a} = ${b}\\]`
            aufgabe0 = `-x - ${a} = ${b}`
            gauthmath = `-x-${a}%3D${b}`
            loesung = -b-a
            mult1 = b-a
            mult2 = -b+a
            mult3 = b+a
            help = help3(a, b)
        break;
        case 4: // a + x = b
            aufgabe = `\\[${a} + x = ${b}\\]`
            aufgabe0 = `${a} + x = ${b}`
            gauthmath = `${a}%2Bx%3D${b}`         
            loesung = b-a;
            mult1 = -b-a
            mult2 = b+a
            mult3 = -b+a
            help = help4(a, b)
        break;
        case 5: // -a + x = b
            aufgabe = `\\[-${a} + x = ${b}\\]`
            aufgabe0 = `-${a} + x = ${b}`
            gauthmath = `-${a}%2Bx%3D${b}`
            loesung = b+a;
            mult1 = -b+a
            mult2 = b-a
            mult3 = -b-a
            help = help5(a, b)
        break;
        case 6: // a - x = b
            aufgabe = `\\[${a} - x = ${b}\\]`
            aufgabe0 = `${a} - x = ${b}`
            gauthmath = `${a}-x%3D${b}`
            loesung = a-b;
            mult1 = -a-b
            mult2 = a+b
            mult3 = -a+b
            help = help6(a, b)
        break;
        case 7: // -a - x = b
            aufgabe = `\\[-${a} - x = ${b}\\]`
            aufgabe0 = `-${a} - x = ${b}` 
            gauthmath = `-${a}-x%3D${b}`
            loesung = -a-b;
            mult1 = a-b
            mult2 = -a+b
            mult3 = a+b
            help = help7(a, b)
        break;

        default:
    }

    gauthmath = "https://www.gauthmath.com/search-question?search=" + gauthmath
    console.log(gauthmath)
    googlemath=aufgabe0 + " solve step by step"
    console.log(googlemath)
    copyTextToClipboard(googlemath);

    /*
    explainer = `You might notice some sections are currently empty - that's because we're busy crafting new and exciting content for you! Our team is working diligently to bring you comprehensive help and in-depth explainer information. These features will be available shortly, enriching your learning experience even further. <br>
    <br>Stay tuned for these updates, and thank you for your patience as we strive to make practicalPi even more helpful and informative for all our users!`
    */

    explainer = `Did you check the help section <kbd> ? </kbd> for the equation: <kbd>${aufgabe0}</kbd>? Cool! Let's break it down a different way and get some tech help.
    <br>
    <br>
    <b>1. Copy this:</b> <kbd>${googlemath}</kbd> (we've copied it for you!) and paste it into a search engine like Google or DuckDuckGo.
    <br>
    <br>
    <b>2. Gauthmath:</b> This app can also walk you through solving the equation. Follow <a href="${gauthmath}" target="_blank">this link to solve ${aufgabe0}</a>
    <br>
    <br>
    The equation you're looking at is a basic example of the form <kbd>x + a = b</kbd>. The more you practice with these types of equations, the easier they become! 
`//



    let mults = multAlternatives([mult1, mult2, mult3], loesung) // remove elements that === loesung
    mults = [...new Set(mults)]; // remove duplilcates within mults
    mults = mults.map(element => [element, false]); // add true, false, false, false to elements
    const randomIndex = Math.floor(Math.random() * (mults.length + 1));
    mults.splice(randomIndex, 0, ...[[loesung, true]]); // add solution at random position
    multiple = mults

    loesung = `\\[x = ${loesung}\\]`

    return [aufgabe,loesung,help,explainer, multiple]
}

//  \\(+${a}\\)   \\(+${a}\\)

export default lin1;

function help0(a,b){
    return(
        `In the equation \\(x + ${a} = ${b}\\), we want to eliminate the \\(+${a}\\) from the \\(x\\).
        To do this, we can subtract \\(${a}\\) from both sides of the equation. This cancels out the \\(+${a}\\) on the left side, and we are left with:
        \\[x = ${b} - ${a}\\]
        Calculating this is a breeze.
        `//
    )
}
function help1(a,b){
    return(
        `In the equation \\(x - ${a} = ${b}\\), we want to eliminate the \\(-${a}\\) from the \\(x\\).
        To do this, we can add \\(${a}\\) to both sides of the equation. This cancels out the \\(-${a}\\) on the left side, and we are left with:
        \\[x = ${b} + ${a}\\]
        Calculating this is a breeze.
        `//
    )
}
function help2(a,b){
    return(
        `In the equation \\(-x + ${a} = ${b}\\), we want to eliminate the \\(+${a}\\) from the \\(-x\\).
        To do this, we can subtract \\(${a}\\) from both sides of the equation. This cancels out the \\(+${a}\\) on the left side, and we are left with:
        \\[-x = ${b} - ${a}\\]
        and hence
        \\[x = ${-b} + ${a}\\]
        Calculating this is a breeze.
        `//
    )
}
function help3(a,b){
    return(
        `In the equation \\(-x - ${a} = ${b}\\), we want to eliminate the \\(-${a}\\) from the \\(-x\\).
        To do this, we can add \\(${a}\\) to both sides of the equation. This cancels out the \\(-${a}\\) on the left side, and we are left with:
        \\[-x = ${b} + ${a}\\]
        and hence
        \\[x = ${-b} - ${a}\\]
        Calculating this is a breeze.
        `//
    )
}
function help4(a,b){
    return(
        `In the equation \\(${a} + x = ${b}\\), we want to eliminate the \\(+${a}\\) from the \\(x\\).
        To do this, we can subtract \\(${a}\\) from both sides of the equation. This cancels out the \\(+${a}\\) on the left side, and we are left with:
        \\[x = ${b} - ${a}\\]
        Calculating this is a breeze.
        `//
    )
}
function help5(a,b){
    return(
        `In the equation \\(-${a} + x = ${b}\\), we want to eliminate the \\(-${a}\\) from the \\(x\\).
        To do this, we can add \\(${a}\\) to both sides of the equation. This cancels out the \\(-${a}\\) on the left side, and we are left with:
        \\[x = ${b} + ${a}\\]
        Calculating this is a breeze.
        `//
    )
}
function help6(a,b){
    return(
        `In the equation \\(${a} - x = ${b}\\), we want to eliminate the \\(+${a}\\) from the \\(-x\\).
        To do this, we can subtract \\(${a}\\) from both sides of the equation. This cancels out the \\(+${a}\\) on the left side, and we are left with:
        \\[-x = ${b} - ${a}\\]
        and hence
        \\[x = ${-b} + ${a}\\]
        Calculating this is a breeze.
        `//
    )
}
function help7(a,b){
    return(
        `In the equation \\(-${a} - x = ${b}\\), we want to eliminate the \\(-${a}\\) from the \\(-x\\).
        To do this, we can add \\(${a}\\) to both sides of the equation. This cancels out the \\(-${a}\\) on the left side, and we are left with:
        \\[-x = ${b} + ${a}\\]
        and hence
        \\[x = ${-b} - ${a}\\]
        Calculating this is a breeze.
        `//
    )
}