import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth' // https://youtu.be/2hR-uWjBAgw?t=816

const firebaseConfig = {
  apiKey: process.env.API_FBKEY,
  authDomain: "crud-webdecoded.firebaseapp.com",
  projectId: "crud-webdecoded",
  storageBucket: "crud-webdecoded.appspot.com",
  messagingSenderId:process.env.MSGSENDER,
  appId: process.env.APP_ID
};

const firebaseConfigAuth = {
  apiKey: "AIzaSyDZK8YN7__XBeWNjQUI6QNcUFsZ7KbJnAE",
  //apiKey: process.env.API_FBKEY_AUTH,
  authDomain: "crud-webdecoded.firebaseapp.com",
  projectId: "crud-webdecoded",
  storageBucket: "crud-webdecoded.appspot.com",
  messagingSenderId:process.env.MSGSENDER_AUTH,
  appId: process.env.APP_ID_AUTH
};

//console.log(process.env.API_FBKEY)
//console.log(firebaseConfig.apiKey)
//console.log(process.env.API_FBKEY_AUTH)

const app = initializeApp(firebaseConfig);
const authApp = initializeApp(firebaseConfigAuth, "authApp");

const firestore = getFirestore(app);
const auth = getAuth(authApp);

export { auth, firestore };
export const googleProvider = new GoogleAuthProvider();