import React, { useState } from 'react';
import { collection, doc, getDocs, updateDoc, addDoc, query, where, serverTimestamp } from 'firebase/firestore';
//import { firestore, auth } from './firebaseConfig'; //20240324
import { firestore } from './firebaseConfig';
//import ColumnDiagram from './ColumnDiagram';

function MultipleChoice({ choices }) {

  const [selected, setSelected] = useState(Array(choices.length).fill(false));
  //const user = { userId: auth?.currentUser?.uid, email: auth?.currentUser?.email } //20240324
  const user = {userId: "Q2nLPSruDShOZUIbP4xK8qDasTq1", email: "info2@redesign.mobi"}
  const [scores, setScores] = useState([])
  const updateScoreInFirebase = async (shouldIncrement = true) => {
    try {
      if (!user.userId) {
        // Handle the case where user is not logged in
        console.error("User is not logged in, cannot update scores.");
        return;
      }
      const scoresRef = collection(firestore, 'scores');
      const q = query(scoresRef, where("email", "==", user.email));
      const docSnap = await getDocs(q);
      if (docSnap.empty) {
        await addDoc(scoresRef, {
          date: serverTimestamp(),
          email: user.email,
          scores: [0, 0, 0, 0, 0, 0, 0],
          userId: user.userId
        });
        console.log("add", user.email)
      } else {
        const docRef = doc(firestore, 'scores', docSnap.docs[0].id);
        const currentScores = docSnap.docs[0].data().scores;
        const firstDocData = docSnap.docs[0].data(); // Access data of the first document
        const lastUpdatedTimestamp = firstDocData.date; // Assuming 'date' is the timestamp field in your document
        const lastUpdated = lastUpdatedTimestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date object
        const today = new Date();
        const isToday = lastUpdated.setHours(0,0,0,0) === today.setHours(0,0,0,0);
        
        if (!isToday) {
          // Shift array elements to the right
          for (let i = currentScores.length - 1; i > 0; i--) {
            currentScores[i] = currentScores[i - 1];
          }
          // Set the first element to 0
          currentScores[0] = 0;
        }

        //console.log(currentScores)
        setScores(currentScores)
        

        // Increment or decrement the first element
        currentScores[0] = shouldIncrement ? currentScores[0] + 1 : currentScores[0] - 1;
  
        await updateDoc(docRef, {
          scores: currentScores,
          date: today
        }).then(() => {
          //console.log("Document successfully updated with new scores and today's date!");
        }).catch((error) => {
          console.error("Error updating document: ", error);
        });
        
      }
    } catch (error) {
      console.error("Error updating score in Firebase:", error);
    }
  };

  const handleClick = (index, isCorrect) => {
    const newSelected = [...selected];
    newSelected[index] = isCorrect ? 'correct' : 'incorrect';
    setSelected(newSelected);

    updateScoreInFirebase(isCorrect); // Pass true for increment, false for decrement
  };

  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {choices.map(([value, isCorrect], index) => (
        <button
          key={index}
          style={{
            backgroundColor: selected[index] === 'correct' ? '#303030' : selected[index] === 'incorrect' ? 'red' : '#2f80ed',
            color: 'white',
            borderRadius: '5px',
            flex: 1,
            margin: '5px',
            opacity: selected[index] === 'correct' || selected[index] === 'incorrect' ? 0.5 : 1, // Reduce opacity if disabled
            cursor: selected[index] === 'correct' || selected[index] === 'incorrect' ? 'not-allowed' : 'pointer', // Change cursor to indicate disabled state
          }}
          onClick={() => handleClick(index, isCorrect)}
          disabled={selected[index] === 'correct' || selected[index] === 'incorrect'} // Disable button if it's marked as 'correct' or 'incorrect'
        >
          {value}
        </button>
      ))}


    </div>

    {/*<div>
    <ColumnDiagram currentScores={scores} />
        </div>*/}
    
    </>
  );
}

export default MultipleChoice;

