import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById('root'));

//console.log("Id-Daten:")
//console.log(domain)
//console.log(clientID)

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain = { domain }
      clientId = { clientID }
      authorizationParams={{ redirect_uri: window.location.origin }}
      //authorizationParams.redirect_uri = {window.location.origin}
      //redirectUri = {window.location.origin}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
serviceWorkerRegistration.register();

